@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.crazy-6{
  position: relative;
  min-height: 100%;
  padding-bottom: 300px;

  .background{
    position: absolute;
    z-index: 0;
    height: 100%;
    bottom:0;
    width: 100%;
    background-image: url("../../../assets/images/frise_terre.png");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  h1{
    position: relative;
    display: inline;
    font-size: 50px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    >span{
      position: absolute;
      right:0;
      top: 18px;

      font-family: "Scope One";
      font-weight: normal;
      font-size: 24px;
      line-height: 41px;
      color: $secondary-color;

      display: inline-block;
      transform: translateX(100%);
      padding-left: $margin-sm;
      white-space: nowrap;

    }
  }

  h3{
    font-weight: normal;
    font-size: 30px;
    line-height: 41px;
  }

  .col-2{
    display: inline-block;
    width: 50%;
    video{
      max-width: 100%;
      outline: none;
    }
  }

  .global{
    position: relative;
    z-index: 1;
    background-image: url("../../../assets/images/feuille_header.png");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .first-section{
    position: relative;
    .container{
      background-image: url("../../../assets/images/phases_lune.png");
      background-position: center 110%;
      background-size: contain;
      background-repeat: no-repeat;
    }

    >img{
      position: absolute;
      right:0;
      bottom: 100px;
      z-index: -2;
      transform: translate(70px, 0);
      max-width: 330px;
      opacity: .3;
    }

  }

  .inputs-section{
    text-align: center;
    padding: 0 0 $margin-xxl 0;

    h3{
      margin-bottom: 0;
    }

    .container-inputs{
      margin: 60px auto;
      width: 100%;

      >div{
        display: inline-block;
        vertical-align: top;
        width: 50%;
        text-align: left;
        &:nth-child(1){
          width: 35%;
          padding-right: $margin-lg;
        }
        &:nth-child(2){
          width: 60%;

          .preview{
            margin-bottom: $margin-lg;
            img{
              width: 100%;
            }
          }

        }
      }


      textarea{
        width: 100%;
        margin-top: $margin-sm;
      }

      textarea+button{
        max-width:390px;
        width: 100%;
        border-color: $secondary-color;
        color: $secondary-color;
      }

      input{
        display: block;
        margin-top: $margin-sm;
        max-width: 300px;
        width: 100%;
      }
    }

  }


  .container{
    @include container();

    .col-2{
      img{
        width: 100%;
      }
      &:nth-child(2){
        padding: 0 $margin-lg;
      }

      button{
        background-color: $primary-color;
        color : $white;
      }

    }


  }

  .section-help{
    position: relative;
    >img{
      position: absolute;
      z-index: -2;
      transform: translate(-100px, -90%);
      max-width: 500px;
      opacity: .6;
    }
  }


}
