@import "../../../styles/variables.scss";

.waiting{
  // background: url("../../assets/images/background/bg_ciel.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .logo{
    margin-bottom: 40px;
    max-width: 115px;
  }

  .count-down{
    text-align: center;
    margin-bottom: $margin-md;
    label{
      font-size: 40px;
    }
    p{
      font-size: 60px;
      line-height: 65px;
      margin: 0;
    }
  }

  .content{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 1200px;

    .container-intro,
    .container-video{
      width: 50%;
      padding: 10px;
    }

    .container-video{
      video{
        width: 100%;
      }
    }

    .container-intro{
      h1{
        margin: 0 0 $margin-sm 0;
      }
    }

  }

  .button{
    margin-top: 30px
  }

}
