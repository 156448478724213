@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.projects{
  height: 100%;

  .container-badge{
    @include fixedBadge;
  }

  .button-iframe-video{
    position:fixed;
    z-index: 10;
    right:20px;
    bottom:40px;

    display: inline-block;
    background-color: white;
    padding: 5px 10px;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    margin: 5px 0;
    white-space: nowrap;
    color : $secondary-color;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

    &:hover{
      color : $primary-color;
    }

  }

  section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: $margin-lg 0;

    &.section-1 {
      background-image: url("../../assets/images/frise_terre.png");
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      padding-bottom: 90px;
      >img{
        max-width: 105px;
        margin: 0px auto 20px auto;
        display: block;
      }
    }

    &.section-2{
      position: relative;
      padding-top: $margin-xxl;
      background-image: url("../../assets/images/background/bg_mer.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .frise{
        position: absolute;
        top:0;
        display: block;
        background-image: url("../../assets/images/frise_vacue.png");
        background-size: contain;
        background-repeat: repeat-x;
        width: 100%;
        padding-top: 8.5%;
        transform: translateY(-40%);
        transition: background-position .3s linear;
      }

      // &:before{
      //   position: absolute;
      //   top:0;
      //   display: block;
      //   background-image: url("../../assets/images/frise_vacue.png");
      //   background-position: center;
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   width: 100%;
      //   padding-top: 8.5%;
      //   transform: translateY(-40%);
      // }

    }

    &.section-3{
      position: relative;
      padding-top: calc(2 * #{$margin-lg});
      padding-bottom: 90px;
      background-image: url("../../assets/images/background/bg_underground3.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: white;

      .frise-ground{
        position: absolute;
        display: block;
        background-image: url("../../assets/images/frise_ground.png");
        background-repeat: repeat-x;
        background-size: contain;
        width: 100%;
        height: 50px;

        transition: background-position .1s linear;

        &.top{
          // padding-top: 10%;
          top:0;
          // transform: translateY(-35%);
        }

        &.bottom{
          bottom:0;
        }

      }

      // &:before{
      //   content : "";
      //   position: absolute;
      //   display: block;
      //   background-image: url("../../assets/images/frise_ground.png");
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   width: 100%;
      //   padding-top: 10%;
      // }
      //
      // &:after{
      //   content : "";
      //   position: absolute;
      //   display: block;
      //   background-image: url("../../assets/images/frise_ground.png");
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   background-size: contain;
      //   width: 100%;
      //   padding-top: 3.5%;
      // }
      //
      // &:before{
      //   top:0;
      //   transform: translateY(-35%);
      // }
      //
      // &:after{
      //   bottom:0;
      // }

    }

    h3{
      text-align: center;
      font-size: $title-md;
      font-weight: normal;
      margin: 0;
    }

    .container-list-projects{

      overflow-x: auto;
      padding: 10px 30px 30px 30px;
      box-sizing: border-box;

      width: 100%;
      margin: 0 auto;

      @media screen and (min-width: 1150px){
        width: 90%;
        margin: 0 auto;
      }

      @media screen and (min-width: $lg-screen){
        width: 80%;
        max-width: 1100px;
        margin: 0 auto;
      }

      .list-projects{
        display: flex;
        justify-content: center;
        align-items: center;
        // width: calc( 151px * 10);
        flex-wrap: wrap;
        padding: 0 10px;
        margin: 0;


        li{
          position: relative;
          display: inline-block;
          width: 136px;
          min-width: 136px;
          min-height: 140px;
          height: 140px;
          background: #FFFFFF;
          border-radius: 15px;
          margin: 0 10px;
          margin-top: 25px;
          text-align: center;
          padding: 32px $margin-sm 15px $margin-sm;
          box-sizing: border-box;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
          cursor: pointer;

          >a{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-decoration: none;
            height: 100%;
          }

          .badge{
            position: absolute;
            top:0;
            left: 50%;
            width: 60px;
            height: 60px;
            border-radius: 30px;
            overflow: hidden;
            background: white;
            transform: translate(-50%,-50%);

            display: flex;
            align-items: center;
            justify-content: center;

            img{
              width: 90%;
            }
          }

          .name-team{
            position: relative;
            display: inline-block;
            text-transform: uppercase;
            font-size: $regular;
            margin: 0 0 $margin-sm 0;
            padding-bottom: 3px;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            &:after{
              content : "";
              position: absolute;
              left: 0;
              bottom: 0;
              display: block;
              height: 1px;
              width: 100%;
              background-color: #5ADFB7;
            }
          }

          .baseline-team{
            margin: 0;
            font-size: $small;
          }

        }
      }

    }

  }
}
