@import "../../../styles/variables.scss";

.view-team{

  .center{

    h1{
      min-width: 300px;
      margin: 0 0 $margin-sm 0;
    }

    p{
      font-size: $medium;
      margin-top: 0;
    }

    .info{
      text-align: center;
    }

    .leader-vote{
      color : $secondary-color;
      margin: 0;

      img{
        position: relative;
        top:8px;
        margin-left: 5px;
        cursor: pointer;
      }

    }

  }


  .list-users{
    display: flex;
    justify-content: center;
    margin-bottom: $margin-lg;
    ul{
      position: relative;
      padding: 0 85px;
      li{
        font-family: 'Scope One', serif;
        list-style-type: none;
        font-size: $medium;
        cursor: pointer;
        color : $secondary-color;

        label{
          position: absolute;
          font-size: $medium;
          font-weight: bold;
          img{
            width: 18px;
          }
        }

        label.leader{
          right:0;
          color : $primary-color;
          transform: translateY(-1px);
          img{
            margin-right: 5px;
          }
        }

        label.user-vote{
          left:0;
          img{
            margin-left: 5px;
          }
        }

      }
    }
  }

  .container-badge{
    display: flex;
    justify-content: center;
  }

}
