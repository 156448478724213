@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.teams{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100%;

  .container-badge{
    @include fixedBadge;
  }

  h1{
    position: relative;
    display: inline-block;
    font-size: $title-md;
    text-align: center;
    margin: 0 auto;
    padding-top: 3px;
    &:after,
    &:before{
      content : "";
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $text-color;
    }

    &:before{
      top: 0;
    }

    &:after{
      bottom: 0;
    }
  }

}
