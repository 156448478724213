@import '../../styles/variables.scss';

.sign-in{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100%;

  img{
    position: absolute;
    top:30px;
    left:50%;
    transform: translateX(-50%);
    max-width: 115px;
  }

  h1{
    margin-bottom: $margin-sm;
    font-weight: normal;
  }

  .container-email{
    max-width: 320px;
    width: 100%;
  }

  input{
    margin-bottom: $margin-sm;
    background-color: $white;
    border: solid 1px $secondary-color;
    padding: $margin-sm;
    max-width: 320px;
    width: 100%;
    margin-bottom: $margin-lg;
    color:#575d61 !important;
  }

  button.disabled{
    opacity: .3;
  }

  .infos{
    p{
      text-align: center;
    }

    &.confirmation p{
      font-size: $large;
    }

    &:not(.confirmation) p{
      font-size: $medium;
    }

    .error{
      font-weight: 700;
    }

    .highlight{
      color : $secondary-color !important;
    }

  }



}
