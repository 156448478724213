// METRICS

$margin-xs : 5px;
$margin-sm : 10px;
$margin-md : 20px;
$margin-lg : 30px;
$margin-xl : 40px;
$margin-xxl : 80px;

$small : 12px;
$regular : 14px;
$medium: 20px;
$large : 25px;
$title-sm : 30px;
$title-md : 40px;
$title-large : 60px;

// COLORS
$text-color : #3C4852;
$white : #FFFFFF;
$primary-color : #9184BF;
$secondary-color : #988080;
$color-error : #FF009A;

// SCREEN WIDTH

$xs-screen : 360px;
$sm-screen : 767px;
$md-screen : 1024px;
$lg-screen : 1324px;
