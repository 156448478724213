@import "../../styles/variables.scss";

.step-navigation{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 90%;
  margin: 0 auto 60px auto;

  li{
    list-style-type: none;
    font-family: "Scope One";
    font-weight: bold;
    font-size: 25px;
    line-height: 35px;
    color: #9184BF;
    cursor: pointer;
    a{
      color: #9184BF;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
