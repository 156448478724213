@import '../../styles/variables.scss';

.textarea{
  display: block;
  outline: none;
  background: transparent;
  border : none;
  border: solid 1px $secondary-color;
  font-family: "Scope One";
  font-size: 20px;
  line-height: 28px;
  color: #A3AAAF;
  background-color: transparent;
  text-align: left;
  resize: none;
  padding: $margin-sm;
  box-sizing: border-box;
  margin: 0 0 $margin-sm 0;

  // &:focus{
  //   color : white;
  //   border-bottom: solid 2px $primary-color;
  // }

}
