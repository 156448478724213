@import "../../styles/variables.scss";

.chrono{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: $margin-md;
  margin: $margin-lg 0 0 0;

  >div:first-child{
    max-width: 430px;
    width: 100%;
    h2{
      width: 100%;
      text-align: center;
      margin: 0;
      font-size: $title-sm;
      font-weight: normal;
    }
  }

  .container-chrono-count{
    position: relative;
    max-width: 430px;
    width: 100%;
    margin-top: -30px;

    .chrono-count{
      position: absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);

      font-size: 50px;
      margin: 0;
    }

    svg{
      width: 100%;
      path{
        transform-origin: 50% 50%;
        transform: rotate(90deg) scaleY(-1);
      }

      .blur {
        filter: url('#dropshadow');
      }

    }

  }

  .chrono-controls{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.disabled{
      button{
        pointer-events: none;
        cursor: not-allowed;
        background-color: transparent !important;
        color : $secondary-color !important;
      }
    }

    button{
      min-width: 200px;
      padding: 10px 20px;

      &:first-child{
        background-color: $secondary-color;
        border-color: $secondary-color;
        color : white;
      }

      &:last-child{
        border-color: $secondary-color;
      }

    }

  }



}
