@import "../../styles/variables.scss";

.modal-overlay{
  position: fixed;
  z-index: 1000;
  top:0; bottom: 0; right: 0; left: 0;
  background-color: rgba(145, 132, 191,.5);
}


.container-modal{
  position: fixed;
  z-index: 1001;
  top:0; bottom: 0; right: 0; left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.modal{
  position: fixed;
  z-index: 1002;
  transform-origin: center center;

  >div{
    position: relative;
    padding: $margin-lg $margin-lg;
    max-width: 500px;
    max-height: 70%;
    background-color: $white;
    box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, .4);
    pointer-events: auto;

    >img{
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

  }



}
