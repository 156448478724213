@import "../../../styles/variables.scss";

.launch{
  // background: url("../../assets/images/background/bg_ciel.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .content-modal{
    text-align: center;

    h2{
      font-family: "Scope One";
      font-weight: normal;
      font-size: $title-md;
      margin: 0 auto $margin-sm auto;
    }

    p:first-child{
      margin-top: 0;
    }

    .email-leader{
      color : $primary-color;
      text-transform: capitalize;
    }

  }

  .content-video{
    width: 100%;
    max-width: 1341px;
    height: 580px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,1);
    transition: all .5s ease-in-out;

    &.min{
      margin-top: 45px;
      max-width: 1189px;
      height: 500px;
    }

  }

  .content{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 970px;

    .container-button,
    .container-intro{
      width: 100%;
      max-width: 540px;
      padding: 10px;
      margin-top:20px;
    }

    .container-intro{
      h1{
        margin: 0 0 $margin-sm 0;
      }

      h3{
        letter-spacing: 1px;
      }

    }

    .container-button{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width:340px;
      margin-top:40px;

      a{
        width:340px;
      }

      button{
        width:340px;
      }

    }


  }

}
