@import "../../../styles/variables.scss";


.container-teams{
  min-height: 70%;
  max-width: 700px;
  width: 100%;

  .info-backward{
    text-align: center;
    width: 100%;
    margin: 0 auto 30px auto;
  }

  .container{
    position: relative;
    z-index: 1;
    min-height: calc(100% - 30px);
    width: 100%;
    background-color: $white;
    margin : 0px auto 0 auto;
    box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, .4);


    .container-scroll{
      height: 100%;
      overflow-y: auto;
      padding: $margin-lg $margin-md;
    }

    button{
      position: absolute;
      z-index: 10;
      right:0;
      top:50%;
      transform: translate(50%,-50%);
    }

  }

}
