.like{
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 18px;
    margin-right: 5px;
  }
  label{
    position: relative;
    top: 3px;
  }
}
