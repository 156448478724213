@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.pitch{
  position: relative;
  min-height: 100%;
  padding-bottom: 300px;

  .background{
    position: absolute;
    z-index: 0;
    height: 100%;
    bottom:0;
    width: 100%;
    background-image: url("../../../assets/images/frise_terre.png");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  h1{
    position: relative;
    display: inline;
    font-size: 50px;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    >span{
      position: absolute;
      right:0;
      top: 18px;

      font-family: "Scope One";
      font-weight: normal;
      font-size: 24px;
      line-height: 41px;
      color: $secondary-color;

      display: inline-block;
      transform: translateX(100%);
      padding-left: $margin-sm;
      white-space: nowrap;

    }
  }

  h3{
    font-weight: normal;
    font-size: 30px;
    line-height: 41px;
    margin-top: 0;
  }

  .col-2{
    display: inline-block;
    vertical-align: top;
    width: 50%;
    button{
      margin-bottom: $margin-md;
    }
  }

  .global{
    position: relative;
    z-index: 1;
    background-image: url("../../../assets/images/feuille_header.png");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .inputs-section{
    position: relative;
    text-align: center;

    >img{
      position: absolute;
      right:0;
      z-index: -2;
      transform: translate(70px, 0);
      max-width: 330px;
      opacity: .3;
    }

    .container{
      background-image: url("../../../assets/images/phases_lune.png");
      background-position: 0% 150px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .inputs-section{
    h3{
      margin-bottom: 0;
    }
  }

  .container{
    @include container();


    .col-2{
      video{
        width: 100%;
      }
      &:nth-child(2){
        padding: 0 $margin-lg;
      }

      button{
        background-color: $primary-color;
        color : $white;
      }

    }

    .container-inputs{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 60px auto;
      width: 100%;

      .preview{
        padding-top: $margin-lg;
        +a{
          margin-top: $margin-lg !important
        }
      }

      .container-loader{
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        .progress{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%,-50%);
          margin: 0;
          font-size: $small;
          font-weight: bold;
          color: $primary-color;
        }
      }

      a{
        display: inline-block;
        margin-top: 220px;
      }

      .preview{
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

        video,img{
          width: 100%;
        }
      }

      .error{
        color : $color-error;
        font-weight : bold;
      }

    }

  }

  .section-help{
    position: relative;
    margin-top: 300px;
    >img{
      position: absolute;
      z-index: -2;
      transform: translate(-100px, -90%);
      max-width: 500px;
      opacity: .6;
    }
  }

}
