@import "./variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Scope+One&display=swap');

html,body{
  overscroll-behavior: none;
}

html,body,#root,#root .App{
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;

  &.cloudy{
    background: url("../assets/images/background/bg_ciel.jpg");
  }

  *{
    box-sizing: border-box;
  }
}

.App{
  position: relative;
  overflow-x: hidden;
}

h1,h2,h3,h4,
label,
a,
p,
button{
  color : $text-color;
  font-family: 'Scope One', serif;
}

button{
  font-size: $medium;
  padding: $margin-md $margin-lg;
  min-width: 235px;
  margin-bottom: $margin-sm;
  outline: none;
  cursor: pointer;

  &:not(.circular).disabled{
    cursor: not-allowed;
    pointer-events: none;
    opacity: .3;
  }

  &.selected{
    border: solid 1px $primary-color !important;
    background-color: $primary-color !important;
    color : $white;
  }

  &:not(.secondary){
    border: solid 1px $primary-color;
    background: $white;
  }

  &.secondary{
    border: solid 1px $secondary-color;
    background: $secondary-color;
    color: $white;
  }

  &.circular{
    width: 160px;
    min-width: 160px !important;
    height: 160px;
    border-radius: calc(162px / 2);
    font-size: 25px;
    &.disabled{
      cursor: not-allowed;
      background-color: #FCFCFC;
      color : #D2D2D2;
      border-color: #D2D2D2
    }
  }

}

.error{
  color : $color-error;
}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
