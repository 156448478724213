@import "../../styles/mixins.scss";

.game{
  height: 100%;
  scroll-behavior: smooth;

  .container-badge{
    @include fixedBadge;
  }

}
