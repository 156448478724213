@import "../../styles/variables.scss";

// HURRY UP
.content-modal{
  text-align: center;

  h2{
    font-family: "Scope One";
    font-weight: normal;
    font-size: $title-md;
    margin: 0 auto $margin-sm auto;
    text-align: center;
    text-transform: uppercase;
  }

  .container-gif{

    img{
      max-width: 100%;
      max-height: 380px;
    }
  }



}
