@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container-help{
  @include container-large();

  >h3{
    position: relative;
    text-align: center;
    margin-bottom: 60px;
    img{
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%,100%);
      width: 12px;
    }
  }

  .col-2{
    display: inline-block;
    width: 50%;
    vertical-align: top;

    &:nth-child(2){
      padding-right: $margin-lg;
    }
    &:nth-child(3){
      padding-left: $margin-lg;
    }
  }


  ul{
    padding: 0;
    li{
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: $margin-lg 0;

      a{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        list-style-type: none;
        text-decoration: none;
        width: 100%;
        label{
          cursor: pointer;
        }
      }

      .doc-container{
        width: 80px;
        height: 80px;
        margin-right: $margin-sm;
        img{
          width: 90%;
        }
      }
    }
  }

  .list-experts{
    display: block;
    width: 100%;
    ul{
      margin-top:-15px;
      padding: 0;
      margin-left: calc(72px + #{$margin-md});
      li{
        margin: 0;
        color : $primary-color;
        width: min-width;
        a{
          cursor: pointer;
          color : $primary-color;
          text-decoration: underline;
        }
      }
    }
  }

}
