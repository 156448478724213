@import "../../../styles/variables.scss";

.list-teams{
  padding: 0;
  width: 400px;
  max-height: 400px;
  margin: $margin-md auto;
  overflow: auto;

  li{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-sm;

    &.current-team{
      cursor: pointer;
      p{
        color : #00BC13;
      }
    }

    .col-right{
      display: flex;
      align-items: center;
      flex : 1;

      .toggle-team{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: $margin-sm;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }

      .badge-team{
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        margin-right: 10px;
        img{
          width: 100%;
        }
      }

      .team-name{
        font-size: $medium;
        margin: 0;
      }
      .team-baseline{
        font-size: $small;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .list-user{
      width: 100%;
      padding-left: 120px;
      margin-bottom: $margin-sm;
      p{
        font-size: $regular;
        color: $primary-color;
        margin: 0px 0 3px 0;
      }
    }

    .count-users{
      display: inline-block;
      margin-right: $margin-md;
      p{
        margin: 0;
        font-size: $regular;
      }
    }

    .btn{
      display: inline-block;
      min-width: 90px;
      a{
        font-size: $regular;
        border: solid 1px $primary-color;
        padding: 5px 10px;
        display:inline-block;
        width: 90px;
        text-align: center;
        cursor: pointer;
      }
    }


  }
}
