@import "../styles/variables.scss";

.logo{
  position: absolute;
  top: 0;
  left:50%;
  width: 104px;
  height: 111px;
  margin-bottom: $margin-lg;
  transform: translateX(-50%);
  img{
    width: 100%;
  }
}
