@import "../../../styles/variables.scss";

.container-loader{
  >div{
    z-index: 100;
  }
}

.form-team{

  padding: 0 $margin-lg;

  &.disabled{
    opacity: .3;
  }

  h1+p {
    font-size: 20px;
  }

  .center{
    text-align: center;

    h1{
      min-width: 300px;
      margin: 0 0 $margin-sm 0;
    }

    p{
      font-size: $medium;
      margin-top: 0;
    }

    .leader-vote{
      color : $secondary-color;
      margin: 0;

      img{
        position: relative;
        top:8px;
        margin-left: 5px;
        cursor: pointer;
      }

    }

  }

  .container-inputs{
    margin: $margin-md 0;
    input{
      width: 80%;
      margin-top: 20px;
    }
  }
}
