.backward{
  position: fixed;
  z-index: 100;
  top: 20px;
  left: 20px;
  font-family: "Scope One";
  font-size: 30px;
  cursor: pointer;

  img{
    left: initial !important;
    transform: initial !important;
    position: relative !important;
    top: -3px !important;
    margin-right: 10px;
  }

}
