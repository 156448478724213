@import "../../styles/variables.scss";

.modal-leader-overlay{
  position: fixed;
  z-index: 1000;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
}

.modal-leader{
  position: fixed;
  z-index: 1001;
  top:50%;
  left:50%;
  width: 100%;
  max-width: 400px;
  background-color: $white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  padding: $margin-sm $margin-md;
  transform: translate(-50%,-50%);

  h2{
    font-size: $large;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: $margin-xs;
  }

  p{
    font-size: $regular;
    margin-top: $margin-xs;
  }

  .close{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

}
