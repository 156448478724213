@import "../../styles/variables.scss";

.create-badge{


    .container{

      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      .container-dropzone{
        position: relative;
        text-align: center;
        label{
          font-family: "Scope One";
          font-size: 20px;
          line-height: 28px;
        }



        .container-badge{
          position: relative;
          width: 200px;
          height: 200px;
          border-radius:  100px;
          margin: 5px 10px;

          display: flex;
          align-items: center;
          justify-content: center;

          .back,
          .front{
            position: absolute;
            top:0;
            width: 90%;
            display: block;
            width: 200px;
            height: 200px;
          }

          .back{
            z-index: 0;
          }

          .front{
            z-index: 1;
          }

        }

        .first-character{
          position: absolute;
          z-index: 3;
          left:50%;
          bottom:0;
          transform: translateX(-50%);

          display: flex;
          align-items: center;
          justify-content: center;

          width: 70px;
          height: 70px;
          border-radius: 44px;
          border: solid 3px black;
          background-color: $white;

          span{
            position: relative;
            top:5px;
            font-family: "Scope One";
            font-size: 60px;
          }

        }

      }

      .grid-backgrounds,
      .grid-pictos{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 170px;

        >div{
          width: 50px;
          height: 50px;
          border: solid 1px rgba(60, 72, 82, 0.5);
          margin: 3px;
          cursor: pointer;

          .front{
            position: relative;
            z-index: 2;
          }

          .back{
            position: relative;
            z-index: 1;
          }

          svg{
            width: 100%;
            height: 100%;
          }
        }

      }

    }

    .container-colorpicker{
      width: 300px;
      margin: $margin-md auto 0 auto;
    }


}
