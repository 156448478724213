@import "../../styles/variables.scss";

.input-text{
  outline: none;
  border: none;
  margin-bottom: $margin-sm;
  border-bottom: 1px solid #DDDDDD;

  font-family: "Scope One";
  font-size: 20px;
  line-height: 28px;
  color: #A3AAAF;
  background-color: transparent;

  // ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  //   color: red;
  // opacity: 1; /* Firefox */
  // }
  //
  // :-ms-input-placeholder { /* Internet Explorer 10-11 */
  //   color: red;
  // }
  //
  // ::-ms-input-placeholder { /* Microsoft Edge */
  //   color: red;
  // }

  &.error{
    border : solid 1px red;
  }
}
