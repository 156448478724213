.content-iframes{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: rgba(0,0,0,1);
  display: flex;
  flex-direction: row;
  z-index: 1002;
  justify-content: center;
  align-items: center;

  .close{
    position: absolute;
    top: 22px;
    left: 22px;
    cursor: pointer;
    transform: scale(2);
  }


}
