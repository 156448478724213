@import "../../styles/variables.scss";

.button-like{
  position:fixed;
  z-index: 10;
  right:20px;
  top:20px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 15px 20px;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  margin: 5px 0;
  white-space: nowrap;
  color : $secondary-color;
  font-size: 20px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

  &:hover{
    color : $primary-color;
  }


  img{
    width: 22px;
    margin-right: 14px;
    cursor: pointer;
  }
  label{
    position: relative;
    top: 3px;
    cursor: pointer;
  }
}
