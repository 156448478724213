@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.project{
  position: relative;
  min-height: 100%;
  width: 100%;

  .background{
    position: absolute;
    z-index: 0;
    height: 100%;
    bottom:0;
    width: 100%;
    background-image: url("../../assets/images/frise_terre.png");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .global{
    position: relative;
    z-index: 1;
    background-image: url("../../assets/images/feuille_header.png");
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;

    padding: $margin-xxl 0 230px 0;
    box-sizing: border-box;
  }

  .global .container{
    position: relative;
    z-index: 1;
    @include container();

    img,
    video{
      width: 100%;
    }

    .header{
      h1{
        font-size: $title-large;
        line-height: $title-large;
        font-weight: normal;
        margin-top: calc(#{$margin-lg} + 15px);
        margin-bottom: 0;
        text-align: center;
      }

      h3{
        margin-top: 0;
        font-size: $title-sm;
        font-weight: normal;
      }

    }

  }

  .brainstorming,
  .crazy6,
  .pitch{
    margin: $margin-lg auto;
  }

  .brainstorming{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    label{
      text-transform: uppercase;
      color : $primary-color;
      font-size: $title-md;
      transition: all .5s ease-in-out;
    }
  }

  .crazy6{
    position: relative;

    h2{
      position: relative;
      display: inline-block;
      font-size: $title-md;
      text-align: center;
      margin: $margin-lg auto;
      padding: 18px 0 15px 0;
      &:after,
      &:before{
        content : "";
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $text-color;
      }

      &:before{
        top: 0;
      }

      &:after{
        bottom: 0;
      }
    }

    p{
      font-size: $medium;
    }

    .machine{
      position: absolute;
      right:0;
      top: -200px;
      z-index: -2;
      transform: translate(70px, 0);
      max-width: 330px;
      opacity: .3;
    }

    .whale{
      position: absolute;
      z-index: -2;
      transform: translate(-100px, -90%);
      max-width: 500px;
      opacity: .6;
      bottom: 0;
    }

  }

}
