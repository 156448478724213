@mixin container{
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
}

@mixin container-large{
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

@mixin fixedBadge{
  position: fixed;
  z-index: 1000;
  top: 20px;
  right: 20px;
}
