@import "../../styles/variables.scss";

.input-file{
  margin: $margin-sm 0 $margin-md 0;
  label{
    background-color: $secondary-color;
    padding: $margin-xs $margin-sm;
    cursor: pointer;

    font-family: "Scope One";
    font-size: $medium;
    color: $white;

  }

  .file-name{
    font-family: "Scope One";
    font-size: $regular;
    line-height: 28px;
    color : $secondary-color;
    margin: $margin-sm 0 0 0;
  }

}
