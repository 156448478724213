@import "../../styles/variables.scss";

.container-slider{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .slider{
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 520px;
    // height: 100%;
    overflow: hidden;

    .slide{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      min-width: 100%;
      color : white;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }

  .slider-navigation{
    position: relative;
    display: inline-block;
    z-index: 10;
    background-color: white;
    border-radius: 8px;
    padding: $margin-sm $margin-xs;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

    ul{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0;
      li{
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        background-color: #D2D2D2;
        margin: 0 5px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        &.active,
        &:hover{
          background-color: $primary-color;
        }
      }
    }
  }

}
