@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.problematic{
  position: relative;
  height: 100%;
  max-height: 100%;

  .button-modal-video{
    position:fixed;
    z-index: 10;
    right:20px;
    bottom:20px;

    display: inline-block;
    background-color: white;
    padding: 5px 10px;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    margin: 5px 0;
    white-space: nowrap;
    color : $secondary-color;
    &:hover{
      color : $primary-color;
    }

  }

  .content-modal{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,0.9);
    display: flex;
    flex-direction: row;
    z-index: 1000;
    justify-content: center;
    align-items: center;

    .close{
      position: absolute;
      top: 22px;
      right: 22px;
      cursor: pointer;
      transform: scale(2);
    }

    video {
      height: 75%;
      width: auto;
      outline: none;
    }

  }

  .global{
    position: relative;
    z-index: 1;
    height: 100%;
  }

  .background{
    position: fixed;
    z-index: 0;
    height: 100%;
    top:0;
    width: 100%;
    background-image: url("../../../assets/images/frise_terre.png");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .container-title{

    padding: $margin-md 0;

    h1{
      text-transform: uppercase;
      text-align: center;
      font-size: 50px;
      font-weight: normal;
      margin: 0;
    }
    p{
      font-weight: bold;
      font-size: 30px !important;
      color : $secondary-color;
      margin: 0;
    }
  }

  .container-title,
  .container-step,
  .container-result{
    @include container-large();
    text-align: center;

    p{
      font-size: $large;
    }

  }

  .container-step{

    .container-answers{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      &.disabled button{
        cursor: auto;
      }

      button{
        width: 30%;
        margin: $margin-md 0;
      }
    }
  }

  .container-result{
    p span{
      color : $primary-color;
    }

    label{
      color : $secondary-color;
      display: block;
      text-align: center;
      font-size: $medium;

    }

    label+p{
      margin-top: $margin-sm;
    }

    .container-button{
      a{
        text-decoration: none;
      }
      button{
        display: block;
        margin: 0 auto $margin-md auto;
      }
    }

  }
}
