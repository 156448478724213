@import "../../styles/variables.scss";

.modal-container-keywords,
.modal-container-problematic{
  h2{
    color : $primary-color;
    margin: 0;
  }
  p{
    font-size: $medium;
    span{
      color : $primary-color;
    }
  }
}

.modal-container-keywords{
  ul{
    padding: 0;
    list-style-type: none;
    min-width: 270px;
    li{
      font-family: 'Scope One', serif;
      color : $secondary-color;
    }
  }
}

.badge{
  position: relative;
  // position: absolute;
  // z-index: 1000;
  // top: 20px;
  // right: 20px;
  display: block;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: $white;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

  .container-problematic{
    position: absolute;
    z-index: 100;
    top : 50%;
    left: -10px;
    transform: translate(-100%,-50%);

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    cursor: pointer;
    label{
      display: inline-block;
      background-color: white;
      padding: 5px 10px;
      border-radius: 8px;
      font-weight: bold;
      cursor: pointer;
      margin: 5px 0;
      white-space: nowrap;
      color : $secondary-color;
      &:hover{
        color : $primary-color;
      }
    }
  }



  .container-image{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;

    img{
      max-width: 100%;
    }

  }

  &.large{
    width: 160px;
    height: 160px;
    border-radius: 80px;

    .first-letter{
      width: 50px;
      height: 50px;
      border-radius: 27px;

      p {
        top:3px;
        font-size: 38px;
      }
    }

  }

  &:not(.large){
    .first-letter{
      width: 38px;
      height: 38px;
      border-radius: 20px;

      p {
        top:3px;
        font-size: 30px;
      }
    }
  }


  .first-letter{
    position: absolute;
    bottom: 0;
    left:50%;
    background-color: $white;
    border: solid 2px black;

    transform: translate(-50%,40%);

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    p{
      position: relative;
      margin: 0;
    }
  }
}
